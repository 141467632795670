import { useDefaultedEvent } from '@/src/lib/events';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { mediaHover, mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';

type ButtonSize = 'default' | 'lg' | 'sm' | 'xs' | 'xss';
type ButtonBorder = 'inherit' | 'default';
type ButtonVariant =
  | 'primary'
  | 'danger'
  | 'danger-transparent'
  | 'green-inverse'
  | 'icon'
  | 'semi-transparent'
  | 'transparent'
  | 'primary-inverse'
  | 'bg-primary'
  | 'bg-secondary'
  | 'bg-tertiary'
  | 'bg-quarternary'
  | 'bg-quinary'
  | 'bg-primary-reverse'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-quaternary'
  | 'outline-placeholder'
  | 'bg-contrast-reverse-semi-transparent';

const ButtonRoundLevels = {
  default: cssVar['radius-input'],
  lg: cssVar['radius-input-lg'],
};

interface ButtonPropsBase {
  size?: ButtonSize;
  active?: boolean;
  variant?: ButtonVariant;
  shape?: 'square' | 'round';
  border?: ButtonBorder;
  isLoading?: boolean;
  isLoadingChildren?: React.ReactNode;
  withKbd?: boolean;
  muted?: boolean;
  roundness?: keyof typeof ButtonRoundLevels;
}

const btnHeight: Record<ButtonSize, string> = {
  default: '2.5rem', // 40px
  sm: '2.125rem', // 34px
  lg: '3.125rem', // 50px
  xs: '1.75rem', // 28px
  xss: '1.5rem', // 24px
};

const btnPadding: Record<ButtonSize, string> = {
  default: '0.875rem', // 14
  lg: '1.125rem', // 18
  sm: '.875rem', // 14
  xs: '0.6125rem', // 12
  xss: '0.375rem', // 6
};

const btnFontSize: Record<ButtonSize, string> = {
  default: '0.8125rem', // 13
  lg: '0.9375rem', // 15
  sm: '0.8125rem', // 13
  xs: '0.75rem', // 10px
  xss: '0.75rem', // 10px
};

const cssSizeProperties = css<ButtonPropsBase>`
  ${(p) => css`
    height: ${btnHeight[p.size || 'default']};
    width: ${p.shape === 'square' || p.shape === 'round'
      ? btnHeight[p.size || 'default']
      : 'max-content'};
    padding: 0 ${btnPadding[p.size || 'default']};
    font-size: ${btnFontSize[p.size || 'default']};
    ${p.size === 'xs' && 'font-weight: 400;'};
    border-radius: ${p.shape === 'round' ? '100%' : ButtonRoundLevels[p.roundness || 'default']};
  `}
`;

const cssBorder = css<ButtonPropsBase>`
  ${(p) => {
    switch (p.border) {
      case 'default':
        return css`
          border-color: ${cssVar['color-border-primary']};

          ${mediaHover} {
            &:hover:not(:disabled) {
              border-color: ${cssVar['color-text-primary']};
            }
          }
        `;
    }
  }}
`;

const cssDisabledStateForColoredButton = css<ButtonPropsBase>`
  &:disabled {
    background: ${cssVar['color-bg-disabled']};
    cursor: not-allowed;
    color: white;
    ${Kbd} {
      color: rgba(255, 255, 255, 0.8);
      background: hsla(${cssVar['color-bg-primary-hsl']}, 0.2);
      svg {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
    ${mediaHover} {
      &:hover:not(:disabled) {
        box-shadow: none;
      }
    }
  }
`;

const cssColorProperties = css<ButtonPropsBase>`
  ${(p) => {
    switch (p.variant) {
      case 'transparent': {
        return css`
          background: transparent;
          color: ${cssVar['color-text-tertiary']};

          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary-hsl']};
          }
          ${mediaHover} {
            &:hover:not(:disabled) {
              background: transparent;
              box-shadow: none;
              color: ${cssVar['color-text-primary']};
            }
          }
          ${p.active &&
          css`
            & {
              background: ${cssVar['color-text-primary']};
              color: ${cssVar['color-bg-primary']};
            }

            ${mediaHover} {
              &:hover:not(:disabled) {
                background: ${cssVar['color-text-primary']};
                color: ${cssVar['color-bg-primary']};
              }
            }
          `}
        `;
      }
      case 'primary-inverse': {
        return css`
          background: #e7e2fc;
          color: ${cssVar['color-app-primary']};

          ${mediaHover} {
            &:hover {
              background: #d3cbfb;
            }
          }
        `;
      }
      case 'green-inverse': {
        return css`
          background: #e5ffe6;
          color: #008d0e;

          ${mediaHover} {
            &:hover:not(:disabled) {
              background: #ccffcf;
            }
          }
        `;
      }
      case 'danger-transparent': {
        return css`
          // todo use css var from other PR
          color: #ff0000;

          ${mediaHover} {
            &:hover:not(:disabled) {
              background: ${cssVar['color-bg-tertiary']};
              color: #ff0000;
              box-shadow: none;
            }
          }

          ${p.active &&
          css`
            & {
              background: ${cssVar['color-text-primary']};
              color: ${cssVar['color-bg-primary']};
            }

            ${mediaHover} {
              &:hover:not(:disabled) {
                background: ${cssVar['color-text-primary']};
                color: ${cssVar['color-bg-primary']};
              }
            }
          `}
        `;
      }
      case 'icon':
      case 'semi-transparent': {
        return css`
          color: ${cssVar['color-text-primary']};

          ${mediaHover} {
            &:hover:not(:disabled) {
              background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
              box-shadow: none;
            }
          }

          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary']};
          }
          ${p.active &&
          css`
            & {
              background: ${cssVar['color-text-primary']};
              color: ${cssVar['color-bg-primary']};
            }

            ${mediaHover} {
              &:hover:not(:disabled) {
                background: ${cssVar['color-text-primary']};
                color: ${cssVar['color-bg-primary']};
              }
            }
          `}
        `;
      }
      case 'bg-primary': {
        return css`
          background-color: ${cssVar['color-bg-primary']};
          color: ${cssVar['color-text-primary']};
          border: 1px solid ${cssVar['color-border-primary']};
        `;
      }
      case 'bg-secondary': {
        return css`
          background-color: ${cssVar['color-bg-secondary-button']};
          color: ${cssVar['color-text-primary']};
          box-shadow: none;
          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary']};
          }

          ${p.muted &&
          !p.active &&
          css`
            > svg {
              color: ${cssVar['color-text-primary']};
            }

            color: ${cssVar['color-text-tertiary']};
          `}

          ${p.active &&
          css`
            background-color: ${cssVar['color-bg-primary-reverse']};
            color: ${cssVar['color-bg-secondary-button']};
          `}
        `;
      }
      case 'bg-tertiary': {
        return css`
          background-color: ${cssVar['color-bg-tertiary']};
          color: ${cssVar['color-text-primary']};
          box-shadow: none;
          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary']};
          }
        `;
      }
      case 'bg-quarternary': {
        return css`
          background-color: ${cssVar['color-bg-quarternary']};
          color: ${cssVar['color-text-primary']};
          box-shadow: none;
          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary']};
          }
        `;
      }
      case 'bg-quinary': {
        return css`
          background-color: ${cssVar['color-bg-quinary']};
          color: ${cssVar['color-text-primary']};
          box-shadow: none;
          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary']};
          }
        `;
      }

      case 'outline-primary': {
        return css`
          background-color: transparent;
          color: ${cssVar['color-text-primary']};
          border: 1px solid ${cssVar['color-text-primary']};
          box-shadow: none;
          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary']};
          }
        `;
      }
      case 'outline-secondary': {
        return css`
          background-color: transparent;
          color: ${cssVar['color-text-secondary']};
          border: 1px solid ${cssVar['color-text-secondary']};
          box-shadow: none;
        `;
      }
      case 'outline-quaternary': {
        return css`
          background-color: transparent;
          color: ${cssVar['color-text-secondary']};
          border: 1px solid ${cssVar['color-text-quaternary']};
          box-shadow: none;
          kbd {
            background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
            color: ${cssVar['color-text-tertiary']};
          }

          &:disabled {
            border: 1px solid rgba(${cssVar['color-text-primary-rgb']}, 0.2);
            color: rgba(${cssVar['color-text-primary-rgb']}, 0.4);
          }
        `;
      }
      case 'outline-placeholder': {
        return css`
          background-color: transparent;
          color: ${cssVar['color-text-placeholder']};
          border: 1px solid ${cssVar['color-text-placeholder']};
          box-shadow: none;
          ${mediaHover} {
            &:hover:not(:disabled) {
              color: ${cssVar['color-text-tertiary']};
            }
          }
        `;
      }
      case 'bg-primary-reverse': {
        return css`
          background-color: ${cssVar['color-bg-primary-reverse']};
          color: ${cssVar['color-bg-primary']};
          border: 1px solid ${cssVar['color-border-primary']};

          ${mediaHover} {
            &:hover:not(:disabled) {
              box-shadow: 0 0 10rem 0 rgba(${cssVar['color-bg-primary-rgb']}, 0.2) inset;
            }
          }
        `;
      }

      case 'danger': {
        return css`
          background-color: #c72727;
          color: white;
          kbd {
            color: rgba(255, 255, 255, 0.8);
          }
          ${cssDisabledStateForColoredButton};
        `;
      }

      case 'bg-contrast-reverse-semi-transparent': {
        return css`
          background: rgba(${cssVar['color-bg-contrast-reverse-rgb']}, 0.04);
          color: ${cssVar['color-text-primary']};
          ${cssDisabledStateForColoredButton};
        `;
      }

      case 'primary':
      default: {
        return css`
          background-color: ${cssVar['color-app-primary']};
          color: white;
          ${Kbd} {
            background: hsla(${cssVar['color-bg-primary-hsl']}, 0.2);
            color: rgba(255, 255, 255, 0.8);
            svg {
              color: rgba(255, 255, 255, 0.8) !important;
              opacity: 0.8;
            }
          }
          ${cssDisabledStateForColoredButton}
        `;
      }
    }
  }}
`;

const LoadingWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { delay: 0, duration: 0.1 },
})`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
`;

export const Button = styled.button.attrs<ButtonPropsBase>(
  ({ isLoading, isLoadingChildren, ...props }) => {
    return {
      ...props,
      isLoading,
      // @TODO loading state, e.g. spinner or custom texts
      children: (
        <>
          {isLoading && (
            <LoadingWrapper>{isLoadingChildren || <Spinner size={20} />}</LoadingWrapper>
          )}
          {props.children}
        </>
      ),
      onPointerUp: useDefaultedEvent((e) => {
        // Unless prevented by the custom handler, blur the button on click to not show the halo
        e.currentTarget.blur();
      }, props.onPointerUp),
    };
  },
)`
  width: max-content;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${cssVar['radius-input']};
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // font
  font-weight: 600;

  outline: 0px solid transparent;
  // outline gets a 0.1s delay to prevent it from showing on quick clicks
  transition:
    0.2s all 0s,
    0.2s outline 0.1s;

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 4px solid rgba(128, 128, 128, 0.2); // gray so it works on dark mode
  }

  ${(p) =>
    p.isLoading &&
    css`
      pointer-events: none;
      * {
        opacity: 0.5;
      }
    `}

  // autosizing for icons
  svg {
    font-size: 1.125em;
    width: 1.125em;
    height: auto;
  }

  kbd {
    background: hsla(${cssVar['color-bg-primary-reverse-hsl']}, 0.1);
    color: ${cssVar['color-text-tertiary']};
  }

  ${cssSizeProperties};

  ${cssBorder}

  ${mediaHover} {
    &:hover:not(:disabled) {
      box-shadow: 0 0 10rem 0 rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.1) inset;
    }
  }

  &:disabled {
    background: ${cssVar['color-bg-secondary-button']};
    cursor: not-allowed;
    color: ${cssVar['color-text-placeholder']};
    ${Kbd} {
      color: rgba(255, 255, 255, 0.8);
      background: hsla(${cssVar['color-bg-primary-hsl']}, 0.2);
      svg {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    ${mediaHover} {
      &:hover:not(:disabled) {
        box-shadow: none;
      }
    }
  }
  ${cssColorProperties};

  ${(p) =>
    p.withKbd &&
    css`
      ${mediaNotMobile} {
        gap: 0.6125rem;
        padding-left: 0.6125rem;
        padding-right: 0.6125rem;
      }
    `}
`;

Button.defaultProps = {
  type: 'button',
};

export interface ButtonProps extends React.ComponentProps<typeof Button> {
  ['data-testid']?: string;
}

export const ButtonIcon = styled(Button).attrs((props) => {
  return {
    size: 'sm',
    shape: 'square',
    variant: 'icon',
    ...props,
  };
})``;
